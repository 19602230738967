
import AppHeader from "@/components/AppHeader.vue";
import { useResult } from "@vue/apollo-composable";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import useError from "@/composables/useError";
import { OrganizationType, useGetOrganisationsQuery } from "@/graphql/types";
import usePluralFilter from "@/composables/usePluralFilter";
import Page from "@/components/Page.vue";
import gql from "graphql-tag";
import Error from "@/components/Error.vue";
import { FilterMatchMode } from "primevue/api";
import OrganizationModal from "@/components/modals/OrganizationModal/OrganizationModal.vue";
import useUser from "@/composables/useUser";
import VDModal from "@/components/modals/VDModal";

// Query
gql`
  query getOrganisations {
    organizations {
      count
      items {
        ...OrganizationParts
      }
    }
  }
`;

export default defineComponent({
  name: "organizations",
  components: {
    AppHeader,
    Page,
    Error,
    OrganizationModal,
  },
  setup() {
    const organizationModal = ref<VDModal<OrganizationType | null> | null>(
      null
    );
    const { isGlobalAdmin } = useUser();
    const router = useRouter();
    const { result, loading, error } = useGetOrganisationsQuery();

    function onRowSelect(data: OrganizationType) {
      router.push({
        name: "organization",
        params: { organizationId: data?.organizationId ?? "" },
        query: {
          title: data.name,
        },
      });
    }

    return {
      organizationModal,
      organizations: useResult(result),
      error: useError(error),
      loading,
      result,
      filters: useFilters(),
      onRowSelect,
      usePluralFilter,
      isGlobalAdmin,
    };
  },
});

function useFilters() {
  return ref({
    global: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
  });
}
